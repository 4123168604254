const { env } = require('../environment')
const fetch = require('node-fetch')

const fetchStyles = () => {
  const url = env.getServiceHost() + 'products/styles?lang=' + env.getLanguage()

  return fetch(url).then(response => response.json())
}

module.exports = { fetchStyles }

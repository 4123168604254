const getSlugs = fmes => {
  const result = []

  fmes.forEach(fme => {
    result.push(fme.slug)
  })

  return result
}

const parseFilterUrl = (
  filter,
  categoryData,
  colorData,
  ratioData,
  artistData,
  stylesData,
  urlSlugAll,
) => {
  let result = {
    categories: [],
    colors: [],
    ratios: [],
    artists: [],
    styles: [],
    ready: false,
  }

  const categorySlugs = getSlugs(categoryData)
  const colorSlugs = getSlugs(colorData)
  const ratioSlugs = getSlugs(ratioData)
  const artistSlugs = getSlugs(artistData)
  const stylesSlugs = getSlugs(stylesData)

  if (
    categorySlugs.length === 0 ||
    colorSlugs.length === 0 ||
    ratioSlugs.length === 0 ||
    artistSlugs.length === 0 ||
    artistSlugs.length === 0 ||
    stylesSlugs.length === 0
  ) {
    return result
  } else {
    result.ready = true
  }

  result.categories = filter
    .split('_')
    .map(elem => '' + elem)
    .filter(elem => '' + elem !== urlSlugAll)

  if (categorySlugs.length > 0) {
    if (result.categories.length > 0) {
      // Filter to only available categories
      result.colors = result.categories.filter(elem => {
        return colorSlugs.indexOf(elem) >= 0
      })
      result.ratios = result.categories.filter(elem => {
        return ratioSlugs.indexOf(elem) >= 0
      })
      result.artists = result.categories.filter(elem => {
        return artistSlugs.indexOf(elem) >= 0
      })
      result.styles = result.categories.filter(elem => {
        return stylesSlugs.indexOf(elem) >= 0
      })
      result.categories = result.categories.filter(elem => {
        return (
          categorySlugs.indexOf(elem) >= 0 ||
          ['customizable'].indexOf(elem) >= 0
        )
      })
    }
  }

  return result
}

module.exports = { parseFilterUrl }

import React from 'react'

export interface EvilSpanProps {
  content: any
}

export default function EvilSpan(props: EvilSpanProps) {
  const content = {
    __html: props.content,
  }

  const dataPropKeys = Object.keys(props)
    .filter(key => key.indexOf('data-') === 0)
    .map(key => {
      return key
    })

  const dataProps: Record<string, string> = {}

  dataPropKeys.map(key => {
    dataProps[key] = props[key]
  })

  return <span {...dataProps} dangerouslySetInnerHTML={content}></span>
}
